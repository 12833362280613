import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { collection, doc, setDoc, onSnapshot, serverTimestamp, query, orderBy, limit } from 'firebase/firestore';
import { fetchBiblePassage } from '../services/bibleService';

const DidaskPage = () => {
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [bibleReference, setBibleReference] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const recognitionRef = useRef(null);
    const transcriptBufferRef = useRef('');

    // Initialize speech recognition
    useEffect(() => {
        if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            recognitionRef.current = new SpeechRecognition();
            
            // Configure recognition settings
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;
            recognitionRef.current.lang = 'en-US'; // Set language explicitly
            
            // Debug output to verify the recognition is working
            console.log('Speech recognition initialized');
            
            recognitionRef.current.onstart = () => {
                console.log('Speech recognition started');
                setError(null);
            };
            
            recognitionRef.current.onresult = (event) => {
                console.log('Speech recognition result received', event);
                
                // Get the latest result
                const resultIndex = event.resultIndex;
                const transcript = event.results[resultIndex][0].transcript;
                
                console.log(`Transcript from speech: "${transcript}"`);
                
                // Update the transcript display
                transcriptBufferRef.current += ' ' + transcript;
                setTranscript(prevTranscript => prevTranscript + ' ' + transcript);
                
                // Process transcript to find Bible references
                processBibleReference(transcriptBufferRef.current);
            };
            
            recognitionRef.current.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
                setError(`Speech recognition error: ${event.error}`);
            };
            
            recognitionRef.current.onend = () => {
                console.log('Speech recognition ended');
                
                // Restart if we're supposed to be listening
                if (isListening) {
                    console.log('Restarting speech recognition');
                    setTimeout(() => {
                        try {
                            recognitionRef.current.start();
                        } catch (e) {
                            console.error('Error restarting speech recognition', e);
                            setError(`Error restarting speech recognition: ${e.message}`);
                        }
                    }, 1000);
                }
            };
        } else {
            setError('Speech recognition not supported in this browser');
            console.error('Speech recognition not supported in this browser');
        }
        
        return () => {
            if (recognitionRef.current) {
                try {
                    recognitionRef.current.stop();
                    console.log('Speech recognition stopped');
                } catch (e) {
                    console.error('Error stopping speech recognition', e);
                }
            }
        };
    }, [isListening]);

    // Listen for Bible reference updates from Firebase
    useEffect(() => {
        try {
            const q = query(collection(db, "didaskReferences"), orderBy("timestamp", "desc"), limit(1));
            
            const unsubscribe = onSnapshot(q, async (snapshot) => {
                if (!snapshot.empty) {
                    setLoading(true);
                    try {
                        const data = snapshot.docs[0].data();
                        
                        // If the reference doesn't have text, fetch it
                        if (!data.text) {
                            const referenceWithText = await fetchBiblePassage(data);
                            setBibleReference(referenceWithText);
                            
                            // Update the reference in Firebase with the text
                            try {
                                await setDoc(snapshot.docs[0].ref, referenceWithText, { merge: true });
                            } catch (error) {
                                console.error("Error updating Firebase with text:", error);
                                // Continue with local data even if Firebase update fails
                            }
                        } else {
                            setBibleReference(data);
                        }
                    } catch (error) {
                        console.error("Error processing Bible reference:", error);
                        setError("Error loading Bible passage. Please try again.");
                    } finally {
                        setLoading(false);
                    }
                }
            }, error => {
                console.error("Firebase snapshot error:", error);
                // If there's a permission error, show instructions but allow local functionality
                if (error.code === 'permission-denied') {
                    setError(
                        "Firebase permission error: You need to update Firestore security rules to allow access to the 'didaskReferences' collection. " +
                        "In Firebase Console, go to Firestore > Rules and add: match /didaskReferences/{document=**} { allow read, write: if true; }"
                    );
                } else {
                    setError(`Firebase error: ${error.message}. Using local functionality only.`);
                }
            });
            
            return () => unsubscribe();
        } catch (error) {
            console.error("Error setting up Firebase listener:", error);
            setError("Error connecting to database. Please try again later.");
        }
    }, []);

    const toggleListening = () => {
        setError(null); // Clear errors when toggling
        
        if (isListening) {
            console.log('Stopping speech recognition');
            try {
                recognitionRef.current.stop();
            } catch (e) {
                console.error('Error stopping speech recognition:', e);
            }
        } else {
            console.log('Starting speech recognition');
            transcriptBufferRef.current = '';
            setTranscript('');
            
            // Add a small delay before starting recognition
            setTimeout(() => {
                try {
                    recognitionRef.current.start();
                    console.log('Speech recognition started successfully');
                } catch (e) {
                    console.error('Error starting speech recognition:', e);
                    setError(`Error starting speech recognition: ${e.message}`);
                }
            }, 100);
        }
        
        setIsListening(!isListening);
    };

    const processBibleReference = (text) => {
        // Simple regex pattern to match common Bible reference formats
        // This can be expanded to be more comprehensive
        const referencePattern = /\b(Genesis|Exodus|Leviticus|Numbers|Deuteronomy|Joshua|Judges|Ruth|1 Samuel|2 Samuel|1 Kings|2 Kings|1 Chronicles|2 Chronicles|Ezra|Nehemiah|Esther|Job|Psalm|Psalms|Proverbs|Ecclesiastes|Song of Solomon|Isaiah|Jeremiah|Lamentations|Ezekiel|Daniel|Hosea|Joel|Amos|Obadiah|Jonah|Micah|Nahum|Habakkuk|Zephaniah|Haggai|Zechariah|Malachi|Matthew|Mark|Luke|John|Acts|Romans|1 Corinthians|2 Corinthians|Galatians|Ephesians|Philippians|Colossians|1 Thessalonians|2 Thessalonians|1 Timothy|2 Timothy|Titus|Philemon|Hebrews|James|1 Peter|2 Peter|1 John|2 John|3 John|Jude|Revelation)\s+(\d+)(?::(\d+)(?:-(\d+))?)?/gi;
        
        const matches = [...text.matchAll(referencePattern)];
        if (matches.length > 0) {
            // Get the most recent match
            const match = matches[matches.length - 1];
            const book = match[1];
            const chapter = match[2];
            const verseStart = match[3] || '1';
            const verseEnd = match[4] || verseStart;
            
            const reference = {
                book,
                chapter,
                verseStart,
                verseEnd,
                fullReference: `${book} ${chapter}:${verseStart}${verseEnd !== verseStart ? `-${verseEnd}` : ''}`,
                timestamp: serverTimestamp()
            };
            
            // Store reference in Firebase
            saveBibleReference(reference);
        }
    };

    const saveBibleReference = async (reference) => {
        try {
            setLoading(true);
            const newRef = doc(collection(db, "didaskReferences"));
            await setDoc(newRef, reference);
        } catch (error) {
            console.error("Error saving Bible reference: ", error);
            
            // Check if it's a permissions error
            if (error.code === 'permission-denied') {
                setError(
                    "Firebase permission error: You need to update Firestore security rules to allow access to the 'didaskReferences' collection. " +
                    "In Firebase Console, go to Firestore > Rules and add: match /didaskReferences/{document=**} { allow read, write: if true; }"
                );
                // Mock the reference locally so we can continue testing
                reference.text = await fetchBiblePassage(reference).then(ref => ref.text);
                setBibleReference(reference);
            } else {
                setError("Error saving reference. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    // Add a manual text input handler for testing
    const [manualInput, setManualInput] = useState('');
    
    const handleManualSubmit = (e) => {
        e.preventDefault();
        if (manualInput.trim()) {
            processBibleReference(manualInput);
            setManualInput('');
        }
    };
    
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Didask</h1>
            <p>Speak to detect Bible references. When detected, they'll be displayed below and shared with everyone.</p>
            
            <div style={{ margin: '20px 0' }}>
                <button 
                    onClick={toggleListening}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: isListening ? '#f44336' : '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                >
                    {isListening ? 'Stop Listening' : 'Start Listening'}
                </button>
            </div>
            
            {/* Manual text input for testing or when speech recognition isn't working */}
            <div style={{ margin: '20px 0', padding: '15px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                <h3>Or Enter Bible Reference Manually</h3>
                <form onSubmit={handleManualSubmit} style={{ display: 'flex', gap: '10px' }}>
                    <input
                        type="text"
                        value={manualInput}
                        onChange={(e) => setManualInput(e.target.value)}
                        placeholder="Try 'John 3:16' or 'Psalm 23:1'"
                        style={{ 
                            flex: 1, 
                            padding: '8px 12px',
                            borderRadius: '4px',
                            border: '1px solid #ccc'
                        }}
                    />
                    <button 
                        type="submit"
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#2196f3',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Submit
                    </button>
                </form>
                <p style={{ fontSize: '0.8em', marginTop: '8px' }}>
                    Use this if speech recognition isn't working in your browser.
                </p>
            </div>
            
            {error && (
                <div style={{ margin: '20px 0', padding: '15px', backgroundColor: '#ffebee', borderRadius: '4px', color: '#c62828' }}>
                    <p><strong>Error:</strong> {error}</p>
                </div>
            )}
            
            {isListening && (
                <div style={{ margin: '20px 0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
                    <h3><span className="listening-indicator"></span>Listening...</h3>
                    <p>{transcript}</p>
                </div>
            )}
            
            {loading && (
                <div style={{ margin: '20px 0', textAlign: 'center' }}>
                    <p>Loading...</p>
                </div>
            )}
            
            {bibleReference && (
                <div style={{ margin: '20px 0', padding: '20px', backgroundColor: '#e1f5fe', borderRadius: '4px' }}>
                    <h2>Detected Reference</h2>
                    <h3>{bibleReference.fullReference}</h3>
                    <div className="bible-quote">
                        <p>{bibleReference.text || "Loading Bible text..."}</p>
                    </div>
                </div>
            )}
            
            {/* Browser compatibility info */}
            <div style={{ marginTop: '40px', padding: '15px', backgroundColor: '#f9f9f9', borderRadius: '4px', fontSize: '0.9em' }}>
                <h4>Browser Compatibility</h4>
                <p>Speech recognition works best in Google Chrome, Edge, and Safari. Firefox requires enabling a flag.</p>
                <p>If you're having issues with speech recognition, try the manual input above.</p>
                <p>Check the browser console for detailed error messages if the speech recognition is not working.</p>
            </div>
            
        </div>
    );
};

export default DidaskPage;