import React, { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import './HillChart.css';

const HillChart = forwardRef(({ dots, updateDotPosition, removeDot }, ref) => {
    const svgRef = useRef(null);
    const hillPathRef = useRef(null);
    const [pathReady, setPathReady] = useState(false);

    // Set pathReady once the component is mounted and refs are set
    useEffect(() => {
        if (hillPathRef.current) {
            setPathReady(true);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        getHillPath: () => hillPathRef.current,
        isPathReady: () => pathReady
    }));

    const getMousePositionOnSVG = (evt) => {
        const CTM = svgRef.current.getScreenCTM();
        return {
            x: (evt.clientX - CTM.e) / CTM.a,
            y: (evt.clientY - CTM.f) / CTM.d
        };
    };

    const getClosestPointOnPath = (path, point) => {
        if (!path) return 0;
        
        const totalLength = path.getTotalLength();
        let closestLength = 0;
        let closestDistance = Infinity;
        const numSamples = 100;
        for (let i = 0; i <= numSamples; i++) {
            let testLength = (i / numSamples) * totalLength;
            let testPoint = path.getPointAtLength(testLength);
            let dx = testPoint.x - point.x;
            let dy = testPoint.y - point.y;
            let distance = dx * dx + dy * dy; // squared distance
            if (distance < closestDistance) {
                closestDistance = distance;
                closestLength = testLength;
            }
        }
        return closestLength;
    };

    return (
        <div>
            <h1>Hill Chart</h1>
            <div style={{
                color: '#666', 
                marginBottom: '20px',
                fontSize: '14px'
            }}>
                Drag dots along the curve to update progress
            </div>
            <div id="hill-chart-container">
                <svg 
                    ref={svgRef} 
                    id="hill-chart" 
                    width="100%" 
                    height="400" 
                    viewBox="0 0 800 400" 
                    preserveAspectRatio="xMidYMid meet"
                    style={{ touchAction: 'none' }}
                >
                    {/* Light grid for better readability */}
                    {Array.from({length: 8}).map((_, i) => (
                        <line 
                            key={`vgrid-${i}`} 
                            x1={100 + i*100} 
                            y1="50" 
                            x2={100 + i*100} 
                            y2="300" 
                            stroke="#e1e4e8" 
                            strokeWidth="1" 
                            strokeDasharray="4,4"
                        />
                    ))}
                    {Array.from({length: 6}).map((_, i) => (
                        <line 
                            key={`hgrid-${i}`} 
                            x1="50" 
                            y1={50 + i*50} 
                            x2="750" 
                            y2={50 + i*50} 
                            stroke="#e1e4e8"
                            strokeWidth="1" 
                            strokeDasharray="4,4"
                        />
                    ))}

                    <path 
                        ref={hillPathRef} 
                        id="hill-path" 
                        d="M50,300 C120,300 250,100 360,60 C380,55 420,55 440,60 C550,100 680,300 750,300" 
                        fill="none" 
                    />
                    <line x1="400" y1="55" x2="400" y2="300" stroke="#e1e4e8" strokeDasharray="5,5" strokeWidth="2" />
                    <text x="200" y="345" textAnchor="middle" fill="#666" style={{fontSize: '14px'}}>Figuring it out</text>
                    <text x="600" y="345" textAnchor="middle" fill="#666" style={{fontSize: '14px'}}>Making it happen</text>
                    {pathReady && dots.map(dot => (
                        <Dot
                            key={dot.id}
                            dot={dot}
                            hillPath={hillPathRef.current}
                            updateDotPosition={updateDotPosition}
                            removeDot={removeDot}
                            getMousePositionOnSVG={getMousePositionOnSVG}
                            getClosestPointOnPath={getClosestPointOnPath}
                        />
                    ))}
                </svg>
            </div>
        </div>
    );
});

const Dot = ({ dot, hillPath, updateDotPosition, removeDot, getMousePositionOnSVG, getClosestPointOnPath }) => {
    const dotRef = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    // Calculate the position whenever hillPath or dot.position changes
    useEffect(() => {
        if (hillPath && typeof dot.position === 'number' && isFinite(dot.position)) {
            try {
                const point = hillPath.getPointAtLength(dot.position);
                setPosition({ x: point.x, y: point.y });
            } catch (error) {
                console.error('Error positioning dot:', error);
                // Use default position if there's an error
                setPosition({ x: 50, y: 300 });
            }
        } else {
            // Default position at the start of the path
            setPosition({ x: 50, y: 300 });
        }
    }, [hillPath, dot.position]);

    // Handle both mouse and touch events for drag
    const handleMouseDown = (e) => {
        // Check if it's not a right-click (button 2)
        if ((e.button === undefined || e.button !== 2) && hillPath) {
            e.preventDefault();
            e.stopPropagation();
            
            // Handle both mouse and touch events
            const handleMove = (moveEvent) => {
                try {
                    // Prevent default to stop scrolling/panning of the page
                    moveEvent.preventDefault();
                    moveEvent.stopPropagation();
                    
                    // Get position from either mouse or touch event
                    const mousePos = moveEvent.touches 
                        ? getMousePositionOnSVG({ 
                            clientX: moveEvent.touches[0].clientX, 
                            clientY: moveEvent.touches[0].clientY 
                          }) 
                        : getMousePositionOnSVG(moveEvent);
                    
                    const newPosition = getClosestPointOnPath(hillPath, mousePos);
                    updateDotPosition(dot.id, newPosition);
                } catch (error) {
                    console.error('Error during drag:', error);
                }
            };

            // End the drag operation for both mouse and touch
            const handleEnd = () => {
                document.removeEventListener('mousemove', handleMove);
                document.removeEventListener('mouseup', handleEnd);
                document.removeEventListener('touchmove', handleMove);
                document.removeEventListener('touchend', handleEnd);
                document.removeEventListener('touchcancel', handleEnd);
            };

            // Add listeners for both mouse and touch
            document.addEventListener('mousemove', handleMove);
            document.addEventListener('mouseup', handleEnd);
            document.addEventListener('touchmove', handleMove, { passive: false });
            document.addEventListener('touchend', handleEnd);
            document.addEventListener('touchcancel', handleEnd);
        }
    };

    // For mobile, handle long press to delete (500ms)
    const handleContextMenu = (e) => {
        e.preventDefault();
        if (window.confirm('Remove this dot?')) {
            removeDot(dot.id);
        }
    };
    
    // Add long press detection for mobile
    useEffect(() => {
        const element = dotRef.current;
        if (!element) return;
        
        let pressTimer;
        let isTouching = false;
        
        const startTouch = () => {
            isTouching = true;
            pressTimer = setTimeout(() => {
                if (isTouching && window.confirm('Remove this dot?')) {
                    removeDot(dot.id);
                }
            }, 750);
        };
        
        const endTouch = () => {
            clearTimeout(pressTimer);
            isTouching = false;
        };
        
        const cancelTouch = () => {
            clearTimeout(pressTimer);
            isTouching = false;
        };
        
        element.addEventListener('touchstart', startTouch);
        element.addEventListener('touchend', endTouch);
        element.addEventListener('touchcancel', cancelTouch);
        element.addEventListener('touchmove', cancelTouch);
        
        return () => {
            clearTimeout(pressTimer);
            element.removeEventListener('touchstart', startTouch);
            element.removeEventListener('touchend', endTouch);
            element.removeEventListener('touchcancel', cancelTouch);
            element.removeEventListener('touchmove', cancelTouch);
        };
    }, [dotRef, removeDot, dot.id]);

    return (
        <g 
            ref={dotRef} 
            transform={`translate(${position.x}, ${position.y})`} 
            data-position={dot.position} 
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            onContextMenu={handleContextMenu}
        >
            <circle 
                r="15" 
                fill={dot.color} 
                stroke="#ffffff" 
                strokeWidth="2" 
                cursor="pointer"
                opacity="0.85"
                style={{ touchAction: 'none' }}
            />
            <text 
                textAnchor="middle" 
                y="-20" 
                className="dot-label"
                fill="#333"
                style={{ pointerEvents: 'none' }}
            >
                {dot.label}
            </text>
        </g>
    );
};

export default HillChart;