// This service handles Bible reference fetching
// In a production app, this would connect to a Bible API that provides NASB translation

// For demo purposes, we're using a sample of verses from NASB
// In a real implementation, you would integrate with a Bible API service
const sampleVerses = {
    // New Testament
    "John 3:16": "For God so loved the world, that He gave His only Son, so that everyone who believes in Him will not perish, but have eternal life.",
    "John 3:17": "For God did not send the Son into the world to judge the world, but so that the world might be saved through Him.",
    "Romans 8:28": "And we know that God causes all things to work together for good to those who love God, to those who are called according to His purpose.",
    "Matthew 11:28": "Come to Me, all who are weary and burdened, and I will give you rest.",
    "Matthew 11:29": "Take My yoke upon you and learn from Me, for I am gentle and humble in heart, and you will find rest for your souls.",
    "Matthew 28:19": "Go, therefore, and make disciples of all the nations, baptizing them in the name of the Father and the Son and the Holy Spirit,",
    "1 Corinthians 13:4": "Love is patient, love is kind, it is not jealous; love does not brag, it is not arrogant.",
    "Philippians 4:13": "I can do all things through Him who strengthens me.",
    "Philippians 4:6": "Do not be anxious about anything, but in everything by prayer and pleading with thanksgiving let your requests be made known to God.",
    "2 Timothy 3:16": "All Scripture is inspired by God and beneficial for teaching, for rebuke, for correction, for training in righteousness;",
    
    // Old Testament
    "Psalm 23:1": "The Lord is my shepherd, I will not be in need.",
    "Psalm 23:2": "He lets me lie down in green pastures; He leads me beside quiet waters.",
    "Proverbs 3:5": "Trust in the Lord with all your heart and do not lean on your own understanding.",
    "Proverbs 3:6": "In all your ways acknowledge Him, and He will make your paths straight.",
    "Genesis 1:1": "In the beginning God created the heavens and the earth.",
    "Isaiah 40:31": "Yet those who wait for the Lord will gain new strength; they will mount up with wings like eagles, they will run and not get tired, they will walk and not become weary.",
    "Isaiah 53:5": "But He was pierced for our offenses, He was crushed for our wrongdoings; the punishment for our well-being was laid upon Him, and by His wounds we are healed.",
    "Jeremiah 29:11": "For I know the plans that I have for you,' declares the Lord, 'plans for prosperity and not for disaster, to give you a future and a hope."
};

/**
 * Fetch Bible passage text from NASB translation
 * @param {Object} reference - Bible reference object with book, chapter, verseStart, verseEnd
 * @returns {Promise<Object>} - Returns the reference with the text added
 */
export const fetchBiblePassage = async (reference) => {
    if (!reference) return null;
    
    const { book, chapter, verseStart, verseEnd, fullReference } = reference;
    
    // In a production app, you would use a Bible API here
    // For example: https://scripture.api.bible/ or https://bible-api.com/
    
    try {
        // Mock API call delay
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Check our sample verses
        const key = `${book} ${chapter}:${verseStart}`;
        let text = sampleVerses[key];
        
        if (!text) {
            // This is a fallback for references we don't have in our sample
            text = `[NASB text for ${fullReference} would be fetched from Bible API]`;
        }
        
        return {
            ...reference,
            text
        };
    } catch (error) {
        console.error("Error fetching Bible passage:", error);
        return {
            ...reference,
            text: "Error fetching Bible passage."
        };
    }
};

/**
 * Stores a Bible reference in local cache and Firebase
 * @param {Object} reference - Bible reference to save
 */
export const storeBibleReference = (reference) => {
    // In a real implementation, you might want to cache references locally
    // This could help reduce API calls
    localStorage.setItem('lastBibleReference', JSON.stringify(reference));
    
    // The Firebase saving is handled in the component
};