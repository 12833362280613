import React from 'react';
import { HashRouter as Router, Route, Link, Routes } from 'react-router-dom';
import HillChartPage from './components/HillChartPage';
import DidaskPage from './components/DidaskPage';
import './App.css';

const App = () => {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={
                        <div>
                            <h1>Some stuff I've made</h1>
                            <nav>
                                <Link to="/hillchart">Hill Chart</Link>
                                <br />
                                <Link to="/didask">Didask</Link>
                                {/*<Link to="/map">Map Visualizer</Link>*/}
                            </nav>
                        </div>
                    } />
                    <Route path="/hillchart" element={<HillChartPage />} />
                    <Route path="/didask" element={<DidaskPage />} />
                    <Route path="/map" element={<div>Map Visualizer</div>} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;