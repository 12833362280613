import React, { useState, useEffect, useRef, useCallback } from 'react';
import HillChart from './HillChart';
import Controls from './Controls';
import PasswordProtection from './PasswordProtection';
import { fetchDots, addDot as addDotService, updateDot, deleteDot } from '../services/dataService';
import { getItem, setItem } from '../lib/localStorage';

// Debounce function
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const HillChartPage = () => {
    const [dots, setDots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const hillChartRef = useRef(null);
    const isDev = process.env.NODE_ENV === 'development';

    useEffect(() => {
        // Check if already authenticated
        const auth = getItem('hill_chart_auth');
        if (auth === 'true') {
            setIsAuthenticated(true);
        }
        
        // Fetch dots when component mounts
        const loadDots = async () => {
            try {
                setIsLoading(true);
                const fetchedDots = await fetchDots();
                setDots(fetchedDots);
            } catch (error) {
                console.error('Error fetching dots:', error);
            } finally {
                setIsLoading(false);
            }
        };
        
        if (isAuthenticated) {
            loadDots();
        } else {
            setIsLoading(false);
        }
    }, [isAuthenticated]);

    const addDot = async (label) => {
        if (hillChartRef.current) {
            // Always start at position 0 (beginning of the hill path)
            const newDot = {
                label,
                position: 0,
                color: getRandomColor()
            };
            
            try {
                const result = await addDotService(newDot);
                setDots(currentDots => [...currentDots, { ...newDot, id: result.id }]);
            } catch (error) {
                console.error('Error adding dot:', error);
            }
        }
    };

    const updateDotPositionInStorage = async (dotId, position) => {
        try {
            await updateDot(dotId, { position });
        } catch (error) {
            console.error('Error updating dot position:', error);
        }
    };

    const debouncedUpdateStorage = useCallback(
        debounce(updateDotPositionInStorage, 500),
        []
    );

    const updateDotPosition = (dotId, position) => {
        if (typeof position === 'number' && isFinite(position)) {
            // Use the functional form of setState to avoid stale closures
            setDots(currentDots => 
                currentDots.map(dot => dot.id === dotId ? { ...dot, position } : dot)
            );
            debouncedUpdateStorage(dotId, position);
        } else {
            console.warn(`Attempted to update dot ${dotId} with invalid position:`, position);
        }
    };

    const removeDot = async (dotId) => {
        try {
            await deleteDot(dotId);
            // Use the functional form of setState to avoid stale closures
            setDots(currentDots => currentDots.filter(dot => dot.id !== dotId));
        } catch (error) {
            console.error('Error removing dot:', error);
        }
    };

    const getRandomColor = () => {
        return '#' + Math.floor(Math.random()*16777215).toString(16);
    };

    if (!isAuthenticated) {
        return <PasswordProtection onAuthenticate={setIsAuthenticated} />;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleLogout = () => {
        setItem('hill_chart_auth', 'false');
        setIsAuthenticated(false);
    };

    return (
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '15px', boxSizing: 'border-box' }}>
            {isDev && (
                <div style={{ 
                    background: '#f5f5f5', 
                    padding: '10px', 
                    textAlign: 'center',
                    borderRadius: '4px',
                    marginBottom: '20px' 
                }}>
                    <span style={{fontSize: '16px', fontWeight: 'bold', color: '#666'}}>
                        🔧 LOCAL MODE: Your data stays on your computer! 🔧
                    </span>
                </div>
            )}
            
            <div style={{
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                marginBottom: '20px',
                borderBottom: '1px solid #eee',
                paddingBottom: '12px',
                flexWrap: 'wrap',
                gap: '10px'
            }}>
                <h2 style={{ 
                    color: '#444', 
                    margin: 0, 
                    fontSize: 'calc(1.2rem + 0.5vw)' 
                }}>
                    Hill Chart
                </h2>
                <button 
                    onClick={handleLogout}
                    style={{
                        padding: '8px 16px',
                        background: '#f1f1f1',
                        color: '#555',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontWeight: '500'
                    }}
                >
                    Logout
                </button>
            </div>
            
            <div style={{marginBottom: '25px'}}>
                <HillChart 
                    ref={hillChartRef}
                    dots={dots}
                    updateDotPosition={updateDotPosition}
                    removeDot={removeDot}
                />
            </div>
            
            <Controls addDot={addDot} />
            
            <div style={{
                marginTop: '30px',
                fontSize: '12px',
                color: '#888',
                textAlign: 'center',
                fontStyle: 'italic'
            }}>
                Inspired by Basecamp's Hill Charts
            </div>
        </div>
    );
};

export default HillChartPage;