// Data service to abstract data storage operations
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../firebase';
import * as localStorage from '../lib/localStorage';

// Determine if we're in development mode
const isDev = process.env.NODE_ENV === 'development';

// Fetch all dots
export const fetchDots = async () => {
  if (isDev) {
    return await localStorage.fetchDots();
  } else {
    const querySnapshot = await getDocs(collection(db, "dots"));
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  }
};

// Add a new dot
export const addDot = async (dot) => {
  if (isDev) {
    return await localStorage.addDotToStorage(dot);
  } else {
    return await addDoc(collection(db, "dots"), dot);
  }
};

// Update a dot
export const updateDot = async (dotId, updates) => {
  if (isDev) {
    await localStorage.updateDotInStorage(dotId, updates);
  } else {
    await updateDoc(doc(db, "dots", dotId), updates);
  }
};

// Delete a dot
export const deleteDot = async (dotId) => {
  if (isDev) {
    await localStorage.deleteDotFromStorage(dotId);
  } else {
    await deleteDoc(doc(db, "dots", dotId));
  }
};