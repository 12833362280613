import React, { useState } from 'react';

const Controls = ({ addDot }) => {
    const [label, setLabel] = useState('');

    const handleAddDot = () => {
        if (label.trim()) {
            addDot(label);
            setLabel('');
        } else {
            alert('Please enter a name for your dot');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddDot();
        }
    };

    return (
        <div style={{
            maxWidth: '600px',
            margin: '0 auto',
            padding: '20px',
            borderRadius: '6px',
            background: '#f9f9f9',
            border: '1px solid #eee'
        }}>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'row', 
                flexWrap: 'wrap', 
                alignItems: 'center', 
                gap: '10px',
                marginBottom: '12px' 
            }}>
                <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width: 'calc(100% - 110px)',
                    minWidth: '200px',
                    flex: '1'
                }}>
                    <img 
                        src="https://img.icons8.com/color/48/000000/cursor--v1.png" 
                        alt="cursor" 
                        style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                            flexShrink: 0
                        }} 
                    />
                    <input
                        type="text"
                        placeholder="Enter dot name..."
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        onKeyPress={handleKeyPress}
                        style={{
                            width: '100%',
                            padding: '10px 12px',
                            borderRadius: '4px',
                            border: '1px solid #ddd',
                            fontSize: '14px'
                        }}
                    />
                </div>
                <button 
                    onClick={handleAddDot}
                    style={{
                        padding: '10px 16px',
                        background: '#4a86e8',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '100px',
                        justifyContent: 'center'
                    }}
                >
                    <span style={{marginRight: '6px'}}>+</span>
                    Add Dot
                </button>
            </div>
            <div style={{
                fontSize: '13px', 
                color: '#777', 
                textAlign: 'center',
                fontStyle: 'italic'
            }}>
                Right-click a dot to delete it
            </div>
        </div>
    );
};

export default Controls;