import React, { useState } from 'react';
import { setItem } from '../lib/localStorage';

const PasswordProtection = ({ onAuthenticate }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();
        // Simple password check - in a real app, this would be server-side
        if (password === 'delivery') {
            setItem('hill_chart_auth', 'true');
            onAuthenticate(true);
        } else {
            setError('Incorrect password');
        }
    };
    
    return (
        <div style={{
            maxWidth: '90%',
            width: '400px',
            margin: '60px auto',
            padding: '30px 20px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            textAlign: 'center',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            background: '#fafafa'
        }}>
            <h2 style={{ color: '#444', marginBottom: '20px' }}>Password Required</h2>
            <p style={{ color: '#666', marginBottom: '20px' }}>Enter the password to access the Hill Chart:</p>
            {error && <p style={{ color: '#d9534f', marginBottom: '15px' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                        padding: '12px',
                        margin: '10px 0 20px',
                        width: '100%',
                        boxSizing: 'border-box',
                        border: '1px solid #ddd',
                        borderRadius: '4px'
                    }}
                    placeholder="Enter password"
                />
                <button
                    type="submit"
                    style={{
                        padding: '12px 24px',
                        background: '#4a86e8',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: '500',
                        width: '100%'
                    }}
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default PasswordProtection;