// Local storage implementation to replace Firebase in development mode
import { v4 as uuidv4 } from 'uuid';

// In-memory storage as a fallback if file operations aren't available
let inMemoryDots = [];
let inMemoryStorage = {};

// Generic localStorage functions
export const getItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item;
  } catch (error) {
    console.error(`Error reading ${key} from localStorage:`, error);
    return inMemoryStorage[key];
  }
};

export const setItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
    inMemoryStorage[key] = value;
  } catch (error) {
    console.error(`Error writing ${key} to localStorage:`, error);
    inMemoryStorage[key] = value;
  }
};

// Helper functions for local storage
const getLocalDots = () => {
  try {
    const storedDots = localStorage.getItem('hillChartDots');
    return storedDots ? JSON.parse(storedDots) : [];
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return inMemoryDots;
  }
};

const saveLocalDots = (dots) => {
  try {
    localStorage.setItem('hillChartDots', JSON.stringify(dots));
    inMemoryDots = dots;
  } catch (error) {
    console.error('Error writing to localStorage:', error);
    inMemoryDots = dots;
  }
};

// Mock Firebase-like operations
export const fetchDots = async () => {
  return getLocalDots();
};

export const addDotToStorage = async (dot) => {
  const newDotWithId = { 
    ...dot, 
    id: uuidv4() 
  };
  
  const dots = getLocalDots();
  dots.push(newDotWithId);
  saveLocalDots(dots);
  
  return { id: newDotWithId.id };
};

export const updateDotInStorage = async (dotId, updates) => {
  const dots = getLocalDots();
  const updatedDots = dots.map(dot => 
    dot.id === dotId ? { ...dot, ...updates } : dot
  );
  saveLocalDots(updatedDots);
};

export const deleteDotFromStorage = async (dotId) => {
  const dots = getLocalDots();
  const filteredDots = dots.filter(dot => dot.id !== dotId);
  saveLocalDots(filteredDots);
};